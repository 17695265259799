import PropTypes from 'prop-types';

const image = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    name: PropTypes.string,
    childImageSharp: PropTypes.objectOf(PropTypes.object),
  }),
]);

export default {
  icon: PropTypes.oneOfType([PropTypes.array, image]),
  image,
  tags: PropTypes.arrayOf(PropTypes.string),
};

import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import { isWindowAvailable } from '../../helpers';

const NavbarLink = ({ className, children, to }) => {
  const isActive = isWindowAvailable() && window.location.pathname === to;

  return (
    <Link className={`${className} navbar-item ${isActive ? 'is-active' : ''}`} to={to}>
      {children}
    </Link>
  );
};

NavbarLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};

NavbarLink.defaultProps = {
  className: '',
};

export default NavbarLink;

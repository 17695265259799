import React from 'react';

import CodingLicksLogo from '../CodingLicksLogo';
import CodingLicksLogoShort from '../CodingLicksLogoShort';
import { Desktop, Mobile } from '../MediaWrappers';
import NavbarLink from './NavbarLink';

export default class Navbar extends React.PureComponent {
  renderLinks = () => (
    <>
      <NavbarLink to="/blog/">Blog</NavbarLink>
      <NavbarLink to="/projects/">Projects</NavbarLink>
      <NavbarLink to="/about/">About</NavbarLink>
    </>
  );

  render() {
    return (
      <nav className="navbar" role="navigation" aria-label="navigation">
        <div className="navbar-brand">
          <NavbarLink className="navbar-brand-link" to="/" title="Home page">
            <Desktop>
              <CodingLicksLogo />
            </Desktop>
            <Mobile>
              <CodingLicksLogoShort />
            </Mobile>
          </NavbarLink>
        </div>
        <div className="navbar-menu">
          <div className="navbar-end">{this.renderLinks()}</div>
        </div>
      </nav>
    );
  }
}

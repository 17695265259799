import '../styles/all.sass';

import PropTypes from 'prop-types';
import React from 'react';

import { setupIconLibrary } from './Icon';

setupIconLibrary();

const Container = ({ children }) => <div className="container theme-cl">{children}</div>;

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;

import PropTypes from 'prop-types';
import React from 'react';

import MDXProvider from './MDXProvider';

const Main = ({ children }) => (
  <main className="main">
    <div className="content">
      <MDXProvider>{children}</MDXProvider>
    </div>
  </main>
);

Main.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Main;

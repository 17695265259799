import { MDXProvider as BaseMDXProvider } from '@mdx-js/react';
import { kebab } from 'case';
import PropTypes from 'prop-types';
import React from 'react';

import Code from './Code';
import Icon from './Icon';

const MAX_ANCHOR_ID_LENGTH = 100;

// eslint-disable-next-line react/prop-types
const withAnchor = WrappedComponent => ({ children }) => {
  const id = kebab(children)
    .replace(/[^\w-]/g, '')
    .replace(/-+/g, '-')
    .replace(/^-|-$/g, '')
    .slice(0, MAX_ANCHOR_ID_LENGTH);

  return React.createElement(
    WrappedComponent,
    {},
    <a id={id} className="anchor" href={`#${id}`} aria-hidden>
      <Icon icon="link" />
    </a>,
    children,
  );
};

const MdxImg = ({ src, alt, ...otherProps }) => (
  <a href={src} target="_blank" rel="noopener noreferrer">
    <img src={src} alt={alt} {...otherProps} />
  </a>
);

MdxImg.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

const MdxPre = props => {
  const { children } = props;

  const isCodeBlock = children && children.props && children.props.originalType === 'code';
  if (isCodeBlock) {
    const { children: codeText, className = '', ...codeProps } = children.props;
    const [, language] = className.split('-');

    return (
      <Code language={language} {...codeProps}>
        {codeText.trim()}
      </Code>
    );
  }

  return <pre {...props} />;
};

const MdxTable = props => <table className="table" {...props} />;

MdxPre.propTypes = {
  children: PropTypes.node.isRequired,
};

const shortCodes = {
  h1: withAnchor('h1'),
  h2: withAnchor('h2'),
  h3: withAnchor('h3'),
  h4: withAnchor('h4'),
  h5: withAnchor('h5'),
  h6: withAnchor('h6'),
  img: MdxImg,
  pre: MdxPre,
  table: MdxTable,
  Icon,
};

const MDXProvider = ({ children }) => (
  <BaseMDXProvider components={shortCodes}>{children}</BaseMDXProvider>
);

MDXProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MDXProvider;

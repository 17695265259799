import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

import propTypes from '../../propTypes';
import Image from '../Image';

const isImageIcon = icon => {
  if (typeof icon === 'string') {
    return icon.includes('/') || icon.includes('\\');
  }
  return icon.childImageSharp;
};

const Icon = ({ className, icon, size }) => {
  if (!icon) {
    return null;
  }

  if (isImageIcon(icon)) {
    return (
      <div className={`icon-img-${size} ${className}`}>
        <Image source={icon} linkToSource={false} />
      </div>
    );
  }

  return (
    <span className={`icon fas fa-${size} ${className}`}>
      <FontAwesomeIcon icon={icon} />
    </span>
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  icon: propTypes.icon,
  size: PropTypes.oneOf(['xs', 'sm', 'lg']),
};

Icon.defaultProps = {
  className: '',
  icon: null,
  size: 'sm',
};

export default Icon;

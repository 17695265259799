import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

import config from '../../config.json';

const { brandColor, windowsMetroColor } = config;

const Head = ({ slug, title: pageTitle, description: pageDescription, robots }) => {
  const site = useSiteMetadata();

  const link = path => [site.url.replace(/\/$/, ''), path.replace(/^\//, '')].join('/');
  const title = [pageTitle, site.title].filter(t => !!t).join(' | ');
  const description = pageDescription || site.description;

  return (
    <Helmet>
      <html lang="en" />
      <title>{title}</title>
      <meta name="description" content={description} />
      {robots && <meta name="robots" content={robots} />}

      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&family=Nunito+Sans&display=swap"
        rel="stylesheet"
      />
      <link rel="apple-touch-icon" sizes="180x180" href={link('apple-touch-icon.png')} />
      <link rel="icon" type="image/png" sizes="32x32" href={link('favicon-32x32.png')} />
      <link rel="icon" type="image/png" sizes="16x16" href={link('favicon-16x16.png')} />
      <link rel="manifest" href={link('site.webmanifest')} />
      <link rel="mask-icon" href={link('safari-pinned-tab.svg" color={brandColor')} />
      <meta name="msapplication-TileColor" content={windowsMetroColor} />
      <meta name="theme-color" content={brandColor} />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={link(slug)} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={link('og-image.jpg')} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:alt" content="Coding Licks" />
    </Helmet>
  );
};

Head.propTypes = {
  slug: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  robots: PropTypes.string,
};

Head.defaultProps = {
  slug: '',
  title: '',
  description: '',
  robots: '',
};

export default Head;

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetadata {
        site {
          siteMetadata {
            url
            title
            description
          }
        }
      }
    `,
  );
  return site.siteMetadata;
};

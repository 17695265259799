import PropTypes from 'prop-types';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Icon from './Icon';

class CopyToClipboardButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      copied: false,
    };
  }

  handleOnCopy = () => {
    this.setState({
      copied: true,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      copied: false,
    });
  };

  render() {
    const { copied } = this.state;
    const { className, text } = this.props;

    return (
      <CopyToClipboard text={text} onCopy={this.handleOnCopy}>
        <div className={`${className} clipboard-copy`}>
          <button
            className="button is-primary is-inverted has-tooltip-left"
            type="button"
            data-tooltip={copied ? 'Copied!' : undefined}
            onMouseLeave={this.handleMouseLeave}
          >
            <Icon icon="copy" />
          </button>
        </div>
      </CopyToClipboard>
    );
  }
}

CopyToClipboardButton.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

CopyToClipboardButton.defaultProps = {
  className: '',
  text: undefined,
};

export default CopyToClipboardButton;

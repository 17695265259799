import PropTypes from 'prop-types';
import React from 'react';

import Container from './Container';
import Footer from './Footer';
import Head from './Head';
import Main from './Main';
import Navbar from './Navbar';

const Layout = ({ children, title, slug, description, robots }) => (
  <Container>
    <Head title={title} description={description} robots={robots} slug={slug} />
    <Navbar />
    <Main>{children}</Main>
    <Footer />
  </Container>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  slug: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  robots: PropTypes.string,
};

Layout.defaultProps = {
  slug: '',
  title: '',
  description: '',
  robots: '',
};

export default Layout;

import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import propTypes from '../propTypes';
import Icon from './Icon';
import Image from './Image';

const contactUrls = {
  GitHub: { icon: ['fab', 'github'], url: 'https://github.com/kael89/' },
  LinkedIn: { icon: ['fab', 'linkedin'], url: 'https://www.linkedin.com/in/koskarvounis/' },
  Email: { icon: 'envelope', url: 'mailto:admin@codinglicks.com' },
  Twitter: { icon: ['fab', 'twitter'], url: 'https://twitter.com/kos_karvounis' },
};

const TechnologyLogo = ({ image, title, url }) => (
  <a className="footer-copyright-icon" key={url} title={title} href={url} rel="noopener noreferrer">
    <Image source={image} linkToSource={false} />
  </a>
);

TechnologyLogo.propTypes = {
  image: propTypes.image.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

const ContactLink = ({ icon, title, url }) => (
  <a key={url} className="footer-contact-icon" title={title} href={url} rel="noopener noreferrer">
    <Icon icon={icon} size="lg" />
  </a>
);

ContactLink.propTypes = {
  icon: propTypes.icon.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

const logoQuery = graphql`
  query Logos {
    bulmaLogo: file(relativePath: { eq: "logo/bulma.png" }) {
      ...LogoIcon
    }
    gatsbyLogo: file(relativePath: { eq: "logo/gatsby.png" }) {
      ...LogoIcon
    }
    netlifyLogo: file(relativePath: { eq: "logo/netlify.png" }) {
      ...LogoIcon
    }
  }
`;

export default () => {
  const data = useStaticQuery(logoQuery);
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer has-text-centered">
      <div className="footer-contact">
        {Object.entries(contactUrls).map(([title, { icon, url }]) => (
          <ContactLink key={title} icon={icon} title={title} url={url} />
        ))}
      </div>
      <div>
        <a className="is-size-5" href="/newsletter">
          Join the newsletter
        </a>
      </div>
      <div className="footer-copyright">
        <div className="is-flex-grow-2">Kostas Karvounis, 2020 - {currentYear}</div>
        <div className="is-flex-grow-1">
          Built with{' '}
          <TechnologyLogo image={data.gatsbyLogo} title="Gatsby" url="https://www.gatsbyjs.org/" />
          <TechnologyLogo image={data.bulmaLogo} title="Bulma" url="https://bulma.io/" />
          <TechnologyLogo image={data.netlifyLogo} title="Netlify" url="https://www.netlify.com/" />
        </div>
        <div className="footer-copyright-monocosm is-flex-grow-1">
          Logo by <a href="https://monocosm.com/">Monocosm</a>
        </div>
      </div>
    </footer>
  );
};

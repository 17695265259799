import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 344">
    <path
      className="cl-logo-left"
      fill="white"
      stroke="white"
      strokeWidth="1"
      d="M 42.00,197.00
         C 33.94,199.99 37.92,207.38 41.00,213.00
           41.00,213.00 49.00,211.00 49.00,211.00
           50.51,216.28 56.85,227.20 59.75,233.00
           59.75,233.00 76.26,266.00 76.26,266.00
           76.26,266.00 83.74,280.00 83.74,280.00
           85.45,283.54 87.32,288.04 87.53,292.00
           87.78,296.67 86.56,300.17 89.16,304.98
           91.67,309.62 95.43,310.38 98.83,313.39
           105.62,319.39 111.88,335.60 121.00,336.74
           125.05,337.25 130.21,335.03 134.00,333.69
           134.00,333.69 156.00,325.69 156.00,325.69
           156.00,325.69 219.00,302.95 219.00,302.95
           231.26,298.46 246.04,291.54 259.00,291.04
           270.66,290.59 297.88,300.00 310.00,304.00
           310.00,304.00 400.00,331.72 400.00,331.72
           411.42,335.16 435.30,343.87 446.00,344.00
           449.03,344.03 453.26,344.26 455.96,342.83
           462.23,339.50 463.49,329.90 460.55,324.00
           458.77,320.43 445.91,306.10 442.42,302.00
           442.42,302.00 402.73,256.00 402.73,256.00
           399.61,252.36 388.19,237.97 384.83,236.60
           383.16,235.92 380.80,236.02 379.00,236.00
           382.97,243.71 392.27,252.60 398.20,260.00
           398.20,260.00 438.02,308.00 438.02,308.00
           438.02,308.00 453.47,325.00 453.47,325.00
           455.59,327.83 457.25,330.55 455.22,333.96
           451.36,340.45 445.81,338.50 440.00,336.86
           440.00,336.86 405.00,326.33 405.00,326.33
           405.00,326.33 316.00,298.98 316.00,298.98
           302.36,294.74 276.10,285.06 263.00,285.00
           263.00,285.00 256.00,285.00 256.00,285.00
           244.14,285.14 232.99,290.81 222.00,294.66
           222.00,294.66 157.00,318.31 157.00,318.31
           157.00,318.31 132.00,327.33 132.00,327.33
           128.98,328.35 124.10,330.38 121.02,329.36
           116.05,327.73 111.18,316.60 108.58,312.00
           108.58,312.00 91.51,281.00 91.51,281.00
           91.51,281.00 88.60,274.00 88.60,274.00
           88.60,274.00 81.22,260.00 81.22,260.00
           71.32,241.23 59.91,221.81 52.42,202.00
           39.71,168.33 31.52,126.45 23.12,91.00
           23.12,91.00 11.58,40.00 11.58,40.00
           11.58,40.00 6.17,15.00 6.17,15.00
           4.93,-0.24 19.96,11.03 26.00,14.31
           26.00,14.31 86.00,46.76 86.00,46.76
           86.00,46.76 136.00,73.69 136.00,73.69
           136.00,73.69 208.00,112.31 208.00,112.31
           208.00,112.31 235.00,126.86 235.00,126.86
           245.04,132.43 254.14,137.86 266.00,138.00
           266.00,138.00 272.00,138.00 272.00,138.00
           272.00,138.00 344.00,128.00 344.00,128.00
           349.35,127.94 355.13,127.46 360.00,130.05
           367.64,134.11 369.32,139.71 372.43,147.00
           377.05,157.84 378.99,158.75 379.00,171.00
           379.00,171.00 384.81,171.00 384.81,171.00
           385.00,168.02 385.18,161.61 384.81,159.00
           384.81,159.00 376.23,140.00 376.23,140.00
           373.27,133.57 370.66,127.94 364.00,124.44
           357.89,121.23 347.90,121.92 341.00,122.00
           341.00,122.00 288.00,129.42 288.00,129.42
           280.07,130.65 269.84,132.95 262.00,131.54
           253.42,129.99 248.41,126.08 241.00,122.26
           241.00,122.26 200.00,100.31 200.00,100.31
           200.00,100.31 96.00,44.69 96.00,44.69
           96.00,44.69 78.00,34.31 78.00,34.31
           78.00,34.31 17.00,1.53 17.00,1.53
           12.11,-0.52 4.16,-1.40 1.17,4.06
           -0.32,6.78 -0.03,11.87 0.00,15.00
           0.09,22.47 2.00,27.88 3.58,35.00
           3.58,35.00 11.35,69.00 11.35,69.00
           11.35,69.00 29.37,147.00 29.37,147.00
           29.37,147.00 37.63,180.00 37.63,180.00
           37.63,180.00 42.00,197.00 42.00,197.00 Z
         M 41.00,201.00
         C 41.00,201.00 44.00,210.00 44.00,210.00
           41.00,207.70 40.01,204.65 41.00,201.00 Z
         M 16.00,4.00
         C 16.00,4.00 16.00,5.00 16.00,5.00
           16.00,5.00 15.00,4.00 15.00,4.00
           15.00,4.00 16.00,4.00 16.00,4.00 Z
         M 17.00,78.00
         C 17.00,78.00 17.00,79.00 17.00,79.00
           17.00,79.00 16.00,78.00 16.00,78.00
           16.00,78.00 17.00,78.00 17.00,78.00 Z
         M 18.00,82.00
         C 18.00,82.00 18.00,83.00 18.00,83.00
           18.00,83.00 17.00,82.00 17.00,82.00
           17.00,82.00 18.00,82.00 18.00,82.00 Z
         M 20.00,91.00
         C 20.00,91.00 20.00,92.00 20.00,92.00
           20.00,92.00 19.00,91.00 19.00,91.00
           19.00,91.00 20.00,91.00 20.00,91.00 Z
         M 23.00,104.00
         C 23.00,104.00 23.00,105.00 23.00,105.00
           23.00,105.00 22.00,104.00 22.00,104.00
           22.00,104.00 23.00,104.00 23.00,104.00 Z
         M 24.00,108.00
         C 24.00,108.00 24.00,109.00 24.00,109.00
           24.00,109.00 23.00,108.00 23.00,108.00
           23.00,108.00 24.00,108.00 24.00,108.00 Z
         M 25.00,112.00
         C 25.00,112.00 25.00,113.00 25.00,113.00
           25.00,113.00 24.00,112.00 24.00,112.00
           24.00,112.00 25.00,112.00 25.00,112.00 Z
         M 26.00,116.00
         C 26.00,116.00 26.00,117.00 26.00,117.00
           26.00,117.00 25.00,116.00 25.00,116.00
           25.00,116.00 26.00,116.00 26.00,116.00 Z
         M 27.00,120.00
         C 27.00,120.00 27.00,121.00 27.00,121.00
           27.00,121.00 26.00,120.00 26.00,120.00
           26.00,120.00 27.00,120.00 27.00,120.00 Z
         M 38.00,167.00
         C 38.00,167.00 45.00,194.00 45.00,194.00
           42.23,191.86 42.22,190.31 41.37,187.00
           40.09,181.98 36.47,171.62 38.00,167.00 Z
         M 126.00,179.00
         C 126.00,179.00 93.00,194.58 93.00,194.58
           88.94,196.35 82.99,198.15 84.33,203.90
           85.25,207.84 91.61,210.10 95.00,211.58
           95.00,211.58 127.00,226.00 127.00,226.00
           127.00,226.00 126.01,215.65 126.01,215.65
           126.01,215.65 118.00,211.22 118.00,211.22
           118.00,211.22 99.00,203.00 99.00,203.00
           99.00,203.00 119.00,193.58 119.00,193.58
           123.09,191.79 126.55,190.92 126.95,185.96
           127.16,183.37 126.95,181.39 126.00,179.00 Z
         M 407.00,231.00
         C 407.00,231.00 422.00,231.00 422.00,231.00
           422.00,231.00 422.00,199.00 422.00,199.00
           422.00,199.00 449.00,222.42 449.00,222.42
           452.59,225.53 457.23,230.75 462.00,231.57
           472.23,233.33 472.99,222.05 473.00,215.00
           473.00,215.00 473.00,175.00 473.00,175.00
           473.00,175.00 457.00,175.00 457.00,175.00
           457.00,175.00 457.00,206.00 457.00,206.00
           457.00,206.00 430.00,182.72 430.00,182.72
           426.02,179.23 421.75,173.95 416.00,174.16
           408.44,174.44 407.08,180.86 407.00,187.00
           407.00,187.00 407.00,231.00 407.00,231.00 Z
         M 240.00,174.43
         C 232.33,175.68 224.23,177.21 218.18,182.53
           208.95,190.65 208.22,211.95 216.21,220.96
           223.88,229.61 237.93,231.87 249.00,232.00
           258.90,232.11 273.95,230.57 281.99,224.45
           293.69,215.52 294.55,192.49 283.70,182.68
           273.72,173.66 252.79,172.85 240.00,174.43 Z
         M 241.00,189.29
         C 247.91,188.63 265.40,188.00 270.61,192.65
           274.94,196.52 278.33,213.53 263.00,215.83
           255.35,216.98 238.45,218.02 232.11,213.30
           226.48,209.12 225.74,198.33 230.43,193.39
           233.15,190.52 237.29,189.91 241.00,189.29 Z
         M 206.00,216.00
         C 206.00,216.00 172.00,216.00 172.00,216.00
           167.37,215.99 163.23,215.98 159.00,213.72
           157.16,212.74 155.64,211.53 154.28,209.95
           146.42,200.78 155.59,190.17 169.00,190.00
           173.96,189.94 191.53,190.80 194.91,188.98
           198.32,187.14 203.97,178.54 206.00,175.00
           206.00,175.00 167.00,175.00 167.00,175.00
           157.91,175.01 149.06,177.07 142.09,183.33
           132.53,191.91 131.47,207.63 138.80,218.00
           149.68,233.39 172.38,231.30 189.00,231.00
           191.06,230.96 193.04,230.99 194.91,229.98
           198.40,228.10 204.09,219.62 206.00,216.00 Z
         M 300.00,190.00
         C 300.00,190.00 328.00,190.00 328.00,190.00
           333.68,190.00 339.77,189.35 345.00,192.00
           354.76,196.94 356.19,208.07 345.99,213.56
           339.47,217.07 324.75,216.00 317.00,216.00
           317.00,216.00 317.00,196.00 317.00,196.00
           317.00,196.00 300.00,196.00 300.00,196.00
           300.00,196.00 300.00,231.00 300.00,231.00
           300.00,231.00 335.00,231.00 335.00,231.00
           343.65,230.99 351.79,229.55 359.00,224.37
           370.40,216.17 374.10,198.57 365.44,187.00
           354.21,171.99 332.63,174.72 316.00,175.00
           314.54,175.03 312.67,174.99 311.30,175.57
           307.77,177.08 301.82,186.46 300.00,190.00 Z
         M 380.00,175.00
         C 380.00,175.00 380.00,231.00 380.00,231.00
           380.00,231.00 396.00,231.00 396.00,231.00
           396.00,231.00 396.00,175.00 396.00,175.00
           396.00,175.00 380.00,175.00 380.00,175.00 Z
         M 552.00,231.00
         C 552.00,231.00 552.00,196.00 552.00,196.00
           552.00,196.00 528.00,196.00 528.00,196.00
           525.45,196.00 519.28,195.74 517.17,196.60
           513.17,198.23 508.65,205.13 507.00,209.00
           507.00,209.00 535.00,209.00 535.00,209.00
           535.00,209.00 535.00,216.00 535.00,216.00
           527.35,216.00 511.10,217.25 505.00,213.28
           491.55,204.51 500.09,190.20 516.00,190.00
           516.00,190.00 536.00,190.00 536.00,190.00
           538.26,189.98 540.80,190.11 542.83,188.98
           545.91,187.25 552.09,178.28 554.00,175.00
           554.00,175.00 512.00,175.00 512.00,175.00
           503.20,175.11 493.34,178.40 487.33,185.09
           477.78,195.73 479.36,213.42 490.04,222.67
           501.03,232.19 515.55,231.00 529.00,231.00
           529.00,231.00 552.00,231.00 552.00,231.00 Z"
    />
    <path
      className="cl-logo-right"
      fill="#a6a8ab"
      stroke="#a6a8ab"
      strokeWidth="1"
      d="M 594.00,175.00
         C 594.00,175.00 594.00,231.00 594.00,231.00
           594.00,231.00 627.00,231.00 627.00,231.00
           627.00,231.00 641.83,229.98 641.83,229.98
           641.83,229.98 653.00,216.00 653.00,216.00
           653.00,216.00 610.00,216.00 610.00,216.00
           610.00,216.00 610.00,175.00 610.00,175.00
           610.00,175.00 594.00,175.00 594.00,175.00 Z
         M 660.00,175.00
         C 660.00,175.00 660.00,231.00 660.00,231.00
           660.00,231.00 676.00,231.00 676.00,231.00
           676.00,231.00 676.00,175.00 676.00,175.00
           676.00,175.00 660.00,175.00 660.00,175.00 Z
         M 757.00,219.00
         C 757.00,219.00 757.00,216.00 757.00,216.00
           757.00,216.00 723.00,216.00 723.00,216.00
           718.37,215.99 714.23,215.98 710.00,213.72
           708.16,212.74 706.64,211.53 705.28,209.95
           697.42,200.78 706.59,190.17 720.00,190.00
           724.96,189.94 742.53,190.80 745.91,188.98
           749.32,187.14 754.97,178.54 757.00,175.00
           757.00,175.00 727.00,175.00 727.00,175.00
           715.13,175.00 706.30,174.23 696.01,181.79
           680.60,193.11 683.15,218.42 702.00,227.10
           712.42,231.90 721.94,231.00 733.00,231.00
           736.30,231.00 743.18,231.45 745.91,229.98
           749.28,228.16 751.26,223.31 757.00,219.00 Z
         M 764.00,175.00
         C 764.00,175.00 764.00,231.00 764.00,231.00
           764.00,231.00 781.00,231.00 781.00,231.00
           781.00,231.00 780.00,208.00 780.00,208.00
           787.75,211.66 800.83,226.75 807.00,229.83
           810.95,231.80 828.55,231.00 834.00,231.00
           834.00,231.00 817.00,216.40 817.00,216.40
           817.00,216.40 797.00,202.00 797.00,202.00
           797.00,202.00 810.00,192.13 810.00,192.13
           810.00,192.13 832.00,176.00 832.00,176.00
           827.54,174.23 809.05,174.18 805.00,176.56
           798.39,180.45 787.45,192.50 780.00,196.00
           780.00,196.00 780.00,175.00 780.00,175.00
           780.00,175.00 764.00,175.00 764.00,175.00 Z
         M 836.00,231.00
         C 836.00,231.00 879.00,231.00 879.00,231.00
           886.56,231.00 896.10,231.55 902.99,228.00
           913.23,222.72 914.56,205.06 904.00,199.44
           896.79,195.61 875.03,197.01 866.00,197.00
           863.33,197.00 857.62,197.40 856.17,194.61
           853.96,190.37 861.80,190.03 864.00,190.00
           864.00,190.00 891.00,190.00 891.00,190.00
           893.52,189.99 896.65,190.20 898.91,188.98
           902.32,187.14 907.97,178.54 910.00,175.00
           910.00,175.00 868.00,175.00 868.00,175.00
           860.64,175.00 852.63,174.19 846.00,178.08
           835.60,184.20 835.51,199.86 846.00,205.91
           853.46,210.21 875.68,208.98 885.00,209.00
           887.22,209.00 898.10,209.32 892.63,214.27
           889.79,216.83 878.10,216.00 874.00,216.00
           874.00,216.00 854.00,216.00 854.00,216.00
           851.73,216.02 849.22,215.89 847.17,217.02
           843.74,218.93 838.05,227.43 836.00,231.00 Z
         M 912.00,234.00
         C 912.00,234.00 918.00,234.00 918.00,234.00
           925.74,233.87 924.31,232.15 930.24,220.00
           930.24,220.00 952.00,172.00 952.00,172.00
           948.76,172.00 943.13,171.39 940.63,173.60
           938.51,175.48 933.94,186.61 932.25,190.00
           932.25,190.00 912.00,234.00 912.00,234.00 Z
         M 955.00,179.00
         C 955.00,179.00 955.99,189.37 955.99,189.37
           955.99,189.37 964.00,194.14 964.00,194.14
           964.00,194.14 983.00,202.00 983.00,202.00
           983.00,202.00 965.00,210.69 965.00,210.69
           965.00,210.69 955.99,215.64 955.99,215.64
           955.99,215.64 955.00,226.00 955.00,226.00
           961.83,224.72 982.04,214.05 990.00,210.58
           993.61,209.00 999.50,206.99 998.63,202.00
           997.80,197.23 991.80,195.58 988.00,193.74
           980.52,190.12 961.94,180.87 955.00,179.00 Z"
    />
  </svg>
);

import Highlight, { defaultProps } from 'prism-react-renderer';
import vsDarkTheme from 'prism-react-renderer/themes/vsDark';
import PropTypes from 'prop-types';
import React from 'react';

import CopyToClipboardButton from './CopyToClipboardButton';

export default class Code extends React.PureComponent {
  renderLineNumber = number => {
    const { showLineNumbers } = this.props;
    return showLineNumbers && <span className="token-line-number">{number}</span>;
  };

  renderLine = (line, index, getLineProps, getTokenProps) => (
    <div {...getLineProps({ line, key: index })}>
      {this.renderLineNumber(index + 1)}
      {line.map((token, key) => (
        <span {...getTokenProps({ token, key })} />
      ))}
      <br />
    </div>
  );

  render() {
    const { children, language } = this.props;

    return (
      <Highlight {...defaultProps} code={children} language={language} theme={vsDarkTheme}>
        {({ className, tokens, getLineProps, getTokenProps }) => (
          <pre className={`code ${className}`}>
            <code>
              {tokens.map((line, i) => this.renderLine(line, i, getLineProps, getTokenProps))}
            </code>
            <CopyToClipboardButton className="is-hidden-mobile" text={`${children}\n\n`} />
          </pre>
        )}
      </Highlight>
    );
  }
}

Code.propTypes = {
  children: PropTypes.node.isRequired,
  language: PropTypes.string,
  showLineNumbers: PropTypes.bool,
};

Code.defaultProps = {
  language: undefined,
  showLineNumbers: false,
};

import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';

import propTypes from '../propTypes';
import { isHash } from '../utils';

const Type = {
  ImageSharp: 'imageSharp',
  String: 'string',
  Invalid: 'invalid',
};

const getType = source => {
  if (isHash(source) && source.childImageSharp) {
    return Type.ImageSharp;
  }
  if (typeof source === 'string' && source) {
    return Type.String;
  }
  return Type.Invalid;
};

const getImageUrl = source => {
  if (getType(source) === Type.String) {
    return source;
  }

  const { original, fluid, fixed } = source.childImageSharp;
  return (original || fluid || fixed || {}).src;
};

/* eslint-disable react/prop-types */
const LinkWrapper = ({ children, url }) => (
  <a href={url} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);
const EmptyWrapper = ({ children }) => <>{children}</>;
/* eslint-enable react/prop-types */

const Image = ({ name, source, linkToSource }) => {
  const type = getType(source);
  if (type === Type.Invalid) {
    return null;
  }

  const ImageWrapper = linkToSource ? LinkWrapper : EmptyWrapper;
  const imageWrapperProps = linkToSource ? { url: getImageUrl(source) } : {};
  const alt = name.replace(/-/g, ' ');

  return (
    <ImageWrapper {...imageWrapperProps}>
      {type === Type.ImageSharp ? (
        <Img {...source.childImageSharp} alt={alt} />
      ) : (
        <img src={source} alt={alt} />
      )}
    </ImageWrapper>
  );
};

Image.propTypes = {
  name: PropTypes.string,
  source: propTypes.image.isRequired,
  linkToSource: PropTypes.bool,
};

Image.defaultProps = {
  name: '',
  linkToSource: true,
};

export default Image;

import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 917 64">
    <path
      className="cl-logo-left"
      fill="white"
      stroke="white"
      strokeWidth="1"
      d="M 45.00,56.00
         C 45.00,56.00 43.02,43.63 43.02,43.63
           43.02,43.63 36.00,39.25 36.00,39.25
           36.00,39.25 19.00,32.00 19.00,32.00
           19.00,32.00 33.00,25.26 33.00,25.26
           42.73,20.54 46.98,19.26 44.00,7.00
           38.55,8.02 14.22,19.60 8.00,22.76
           3.98,24.80 -0.79,27.58 0.55,32.96
           1.79,37.91 7.86,40.02 12.00,41.86
           12.00,41.86 45.00,56.00 45.00,56.00 Z
         M 155.00,2.44
         C 147.69,3.79 138.74,5.82 133.34,11.33
           124.22,20.62 124.64,43.69 134.18,52.53
           145.50,63.02 167.67,62.87 182.00,60.56
           188.81,59.46 196.51,57.54 201.72,52.53
           211.51,42.59 211.91,21.12 201.72,11.10
           191.93,1.13 168.28,0.74 155.00,2.44 Z
         M 323.00,61.00
         C 323.00,61.00 340.00,61.00 340.00,61.00
           340.00,61.00 340.00,31.00 340.00,31.00
           340.00,31.00 368.00,54.95 368.00,54.95
           372.47,58.86 378.62,62.96 384.90,60.40
           390.78,58.00 390.99,48.33 391.00,43.00
           391.00,43.00 391.00,3.00 391.00,3.00
           391.00,3.00 373.00,3.00 373.00,3.00
           373.00,3.00 373.00,32.00 373.00,32.00
           373.00,32.00 349.00,11.15 349.00,11.15
           345.23,7.84 340.10,2.88 335.00,2.23
           324.28,0.86 323.01,9.91 323.00,18.00
           323.00,18.00 323.00,61.00 323.00,61.00 Z
         M 124.00,44.00
         C 124.00,44.00 97.00,44.00 97.00,44.00
           91.40,44.00 85.30,44.46 80.00,42.44
           71.06,39.03 65.79,30.53 75.04,23.65
           84.93,16.29 103.00,22.76 112.00,18.57
           115.41,16.98 117.84,14.01 119.96,11.00
           121.98,8.12 122.94,6.34 124.00,3.00
           124.00,3.00 81.00,3.00 81.00,3.00
           72.56,3.10 63.26,6.10 57.18,12.18
           47.62,21.76 47.98,38.59 55.93,48.96
           66.16,62.32 86.99,61.02 102.00,61.00
           105.32,60.99 108.94,61.18 112.00,59.69
           117.46,57.02 122.20,49.65 124.00,44.00 Z
         M 216.00,20.00
         C 216.00,20.00 249.00,20.00 249.00,20.00
           252.84,20.01 256.43,19.86 260.00,21.57
           270.06,26.38 271.62,36.16 261.00,41.55
           254.85,44.67 242.05,44.00 235.00,44.00
           235.00,44.00 235.00,24.00 235.00,24.00
           235.00,24.00 216.00,24.00 216.00,24.00
           216.00,24.00 216.00,61.00 216.00,61.00
           216.00,61.00 250.00,61.00 250.00,61.00
           256.23,60.99 264.17,60.15 270.00,57.88
           288.31,50.75 294.51,25.94 280.90,12.09
           270.37,1.38 255.64,3.00 242.00,3.00
           238.06,3.00 230.34,2.47 227.01,4.17
           222.10,6.70 217.28,14.76 216.00,20.00 Z
         M 296.00,3.00
         C 296.00,3.00 296.00,61.00 296.00,61.00
           296.00,61.00 314.00,61.00 314.00,61.00
           314.00,61.00 314.00,3.00 314.00,3.00
           314.00,3.00 296.00,3.00 296.00,3.00 Z
         M 470.00,61.00
         C 470.00,61.00 470.00,24.00 470.00,24.00
           470.00,24.00 445.00,24.00 445.00,24.00
           441.78,24.00 435.84,23.67 433.04,24.85
           427.86,27.05 424.07,33.75 423.00,39.00
           423.00,39.00 451.00,39.00 451.00,39.00
           451.00,39.00 451.00,44.00 451.00,44.00
           443.97,44.00 431.10,44.73 425.00,41.69
           416.13,37.26 414.05,28.72 423.02,22.99
           432.51,16.92 451.06,22.16 459.00,19.11
           462.86,17.63 465.48,14.24 467.84,11.00
           470.02,8.01 470.88,6.51 472.00,3.00
           472.00,3.00 429.00,3.00 429.00,3.00
           420.10,3.04 410.64,5.72 404.18,12.18
           393.67,22.72 395.06,42.87 406.17,52.47
           412.11,57.60 419.50,59.20 427.00,60.27
           427.00,60.27 470.00,61.00 470.00,61.00 Z
         M 157.00,19.30
         C 163.51,18.65 180.60,18.12 185.79,21.85
           191.49,25.95 191.39,36.89 185.79,41.01
           181.83,43.92 173.00,44.46 168.00,44.82
           163.29,45.15 152.76,43.81 149.22,40.58
           143.39,35.26 142.54,22.17 157.00,19.30 Z"
    />
    <path
      className="cl-logo-right"
      fill="#a6a8ab"
      stroke="#a6a8ab"
      strokeWidth="1"
      d="M 510.00,3.00
         C 510.00,3.00 510.00,61.00 510.00,61.00
           510.00,61.00 549.00,60.42 549.00,60.42
           549.00,60.42 559.71,58.98 559.71,58.98
           559.71,58.98 572.00,44.00 572.00,44.00
           572.00,44.00 528.00,44.00 528.00,44.00
           528.00,44.00 528.00,3.00 528.00,3.00
           528.00,3.00 510.00,3.00 510.00,3.00 Z
         M 576.00,3.00
         C 576.00,3.00 576.00,61.00 576.00,61.00
           576.00,61.00 594.00,61.00 594.00,61.00
           594.00,61.00 594.00,3.00 594.00,3.00
           594.00,3.00 576.00,3.00 576.00,3.00 Z
         M 675.00,44.00
         C 675.00,44.00 648.00,44.00 648.00,44.00
           642.40,44.00 636.30,44.46 631.00,42.44
           622.06,39.03 616.79,30.53 626.04,23.65
           635.95,16.28 654.02,22.78 663.00,18.57
           666.38,16.98 668.70,13.95 670.84,11.00
           673.02,8.01 673.88,6.51 675.00,3.00
           675.00,3.00 633.00,3.00 633.00,3.00
           626.38,3.08 620.76,4.56 615.00,7.88
           598.77,17.23 597.91,39.45 611.04,51.90
           618.01,58.50 629.63,60.89 639.00,61.00
           644.29,61.06 658.85,61.70 663.00,59.69
           665.62,58.41 667.85,56.13 669.83,54.03
           673.45,50.19 674.58,49.28 675.00,44.00 Z
         M 680.00,3.00
         C 680.00,3.00 680.00,61.00 680.00,61.00
           680.00,61.00 699.00,61.00 699.00,61.00
           699.00,61.00 698.00,39.00 698.00,39.00
           698.00,39.00 723.00,59.18 723.00,59.18
           726.93,60.95 731.77,60.99 736.00,61.00
           736.00,61.00 801.00,61.00 801.00,61.00
           814.06,60.98 828.25,59.24 828.96,43.00
           829.63,27.64 820.05,25.02 807.00,25.00
           797.10,24.98 783.04,26.39 774.00,23.00
           774.00,23.00 774.00,21.00 774.00,21.00
           783.69,19.26 795.95,20.02 806.00,20.00
           808.83,20.00 812.34,20.13 815.00,19.11
           820.75,16.90 826.19,8.69 828.00,3.00
           828.00,3.00 787.00,3.00 787.00,3.00
           779.72,3.00 770.61,2.12 764.01,5.19
           750.67,11.37 751.20,31.05 764.01,36.39
           774.41,40.72 797.25,37.72 810.00,40.00
           810.00,40.00 810.00,42.00 810.00,42.00
           799.91,44.92 789.42,44.00 779.00,44.00
           775.28,44.00 767.15,43.53 764.04,44.93
           759.21,47.11 754.54,54.48 752.00,59.00
           752.00,59.00 734.00,43.65 734.00,43.65
           734.00,43.65 716.00,30.00 716.00,30.00
           716.00,30.00 750.00,6.00 750.00,6.00
           750.00,6.00 750.00,4.00 750.00,4.00
           743.91,2.52 726.56,2.15 721.00,4.77
           717.55,6.39 702.30,19.51 698.00,23.00
           698.00,23.00 698.00,3.00 698.00,3.00
           698.00,3.00 680.00,3.00 680.00,3.00 Z
         M 828.00,64.00
         C 832.37,64.00 838.14,64.59 841.61,61.49
           844.67,58.76 849.38,47.22 851.31,43.00
           851.31,43.00 870.00,0.00 870.00,0.00
           866.21,0.00 860.42,-0.58 857.33,1.74
           853.59,4.54 850.43,13.56 848.24,18.00
           848.24,18.00 835.77,45.00 835.77,45.00
           833.13,50.69 829.13,57.99 828.00,64.00 Z
         M 871.00,7.00
         C 871.07,10.07 871.37,16.10 873.01,18.67
           875.77,23.02 890.77,28.59 896.00,30.00
           896.00,30.00 896.00,32.00 896.00,32.00
           891.92,33.16 875.17,40.64 872.98,43.63
           871.21,46.05 871.03,52.97 871.00,56.00
           880.94,53.97 895.32,46.33 905.00,41.78
           909.14,39.83 915.10,38.05 916.25,32.96
           917.59,27.04 911.41,23.95 907.00,22.00
           896.74,17.47 881.53,8.63 871.00,7.00 Z"
    />
  </svg>
);

const compareStrings = (a, b) => a.localeCompare(b, undefined, { numeric: true });

const compareNumbers = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

const compare = (a, b, options = {}) => {
  const [x, y] = options.order === 'desc' ? [b, a] : [a, b];
  const typeDescription = [typeof x, typeof y].join(',');

  switch (typeDescription) {
    case 'string,string':
      return compareStrings(x, y);
    case 'number,number':
      return compareNumbers(x, y);
    case 'number,string':
      return -1;
    case 'string,number':
      return 1;
    default:
      return compareStrings(JSON.stringify(x), JSON.stringify(y));
  }
};

export const fieldComparator = (key, options) => (itemA, itemB) =>
  compare(itemA[key], itemB[key], options);

export const isHash = input => input && !Array.isArray(input) && Object.keys(input).length > 0;

export const mapValues = (object, mapValue) =>
  Object.fromEntries(Object.keys(object).map(key => [key, mapValue(object[key], key)]));

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faGit,
  faGitAlt,
  faGithub,
  faLinkedin,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { faCopy, faEnvelope, faLink } from '@fortawesome/free-solid-svg-icons';

export const setupIconLibrary = () => {
  library.add(faCopy, faEnvelope, faGit, faGitAlt, faGithub, faLink, faLinkedin, faTwitter);
};

import config from '../config.json';

export const filenameToSlug = filename =>
  filename
    .replace(new RegExp(`^${config.contentDir}/?`), '/')
    .replace(/.jsx?$/, '/')
    .replace(/\/index\/$/, '/');

export const mdxToEntities = mdxResults =>
  mdxResults.edges.map(({ node }) => ({
    ...node.fields,
    ...node.frontmatter,
  }));

export const isWindowAvailable = () => typeof window !== 'undefined';
